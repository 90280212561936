export default {
  vBlack: "#01161e",
  vGray: "#4C5F6B",
  vDarkBlue: "#4000F0",
  vBlue: "#00b0f0",
  vGreen: "#7AC74F",
  vOrange: "#F04000",
  vRed: "#E54B4B",
  vWhite: "#EAEAEA",
};
