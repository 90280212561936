import { ButtonUnstyled } from "@mui/base";
import { useEffect, useState } from "react";
import "../styles/styles.css";
import IndexContainer from "./IndexContainer";
import feature01 from "../assets/features/flights.png";
import feature02 from "../assets/features/hotels.png";
import feature03 from "../assets/features/notifications.png";
import feature04 from "../assets/features/cars.png";
import feature05 from "../assets/features/freeze.png";
import hero01 from "../assets/hero01.png";
import hero02 from "../assets/hero02.png";
import hero03 from "../assets/hero03.png";
import howitworks01 from "../assets/howitworks01.png";
import howitworks02 from "../assets/howitworks02.png";
import howitworks03 from "../assets/howitworks03.png";
// import * as mapData from "../assets/ne_50m_land.json";
import Colors from "../styles/Colors";
import screen01 from "../assets/screens/screen01.png";
import screen02 from "../assets/screens/screen02.png";
import screen03 from "../assets/screens/screen03.png";
import GetMobileApp from "./GetMobileApp";
import Carousel from "react-multi-carousel";

export default function Landing() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [getMobileAppOpen, setGetMobileAppOpen] = useState(false);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  const updateWidthAndHeight = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };
  return (
    <div
      style={{
        background: "linear-gradient(#fff,#eaeaea)",
        minHeight: "100vh",
      }}
    >
      <GetMobileApp
        visible={getMobileAppOpen}
        handleClose={() => setGetMobileAppOpen(false)}
        width={windowWidth}
        height={windowHeight}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: 20,
          minHeight: "90vh",
          justifyContent: "space-evenly",
          zIndex: 1,
          overflow: "hidden",
          flexWrap: "wrap",
          // marginTop: -40,
        }}
      >
        <div
          style={{
            zIndex: 1,
            top: 160,
            maxWidth: 500,
            // textShadow: "0px 0px 4px rgba(255,255,255,1)",
          }}
        >
          <h1
            style={{
              zIndex: 1,
            }}
          >
            Say hello to the world <b>together</b>.
          </h1>
          <h3
            style={{
              zIndex: 1,
            }}
          >
            Get inspired, get booked, and get ready for your next trip with
            Vaia.
          </h3>
          <ButtonUnstyled
            className="pressable"
            onClick={() => setGetMobileAppOpen(true)}
            style={{
              background: Colors.vOrange,
              color: Colors.vWhite,
              border: "1px solid #F04000",
              borderRadius: 4,
              padding: 4,
              cursor: "pointer",
              margin: 16,
              boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
              zIndex: 1,
            }}
          >
            <p style={{ color: Colors.vWhite, whiteSpace: "nowrap" }}>
              Send app to your phone
            </p>
          </ButtonUnstyled>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img
            style={{
              height: 420,
              width: 200,
              borderRadius: 8,
              margin: 20,
              marginTop: 40,
              objectFit: "cover",
              boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
            }}
            src={hero01}
            alt="hero"
          />
          <img
            style={{
              height: 420,
              width: 200,
              borderRadius: 8,
              margin: 20,
              marginTop: 120,
              objectFit: "cover",
              boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
            }}
            src={hero02}
            alt="hero"
          />
          <img
            style={{
              height: 420,
              width: 200,
              borderRadius: 8,
              margin: 20,
              marginTop: 80,
              objectFit: "cover",
              boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
            }}
            src={hero03}
            alt="hero"
          />
        </div>
        {/* <div
          style={{
            opacity: 0.3,
            display: "flex",
            alignItems: "stretch",
            justifyContent: "stretch",
            minWidth: 900,
            paddingRight: "8%",
          }}
        >
          <ComposableMap>
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    stroke={Colors.vBlue}
                    fill="transparent"
                    style={{
                      default: { outline: "none" },
                      hover: { outline: "none" },
                      pressed: { outline: "none" },
                    }}
                    tabIndex={-1}
                  />
                ))
              }
            </Geographies>
          </ComposableMap>
        </div> */}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 20,
          zIndex: 1,
        }}
      >
        <IndexContainer
          heading={"Vaia App"}
          title={"Go together, better."}
          body={
            "Whether you're planning a complex trip, or throwing together a last-minute adventure with friends, Vaia has you covered."
          }
          content={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                contain: "content",
              }}
            >
              <img
                src={screen01}
                alt="feature"
                style={{
                  width: 200,
                  position: "absolute",
                  right: "10%",
                  zIndex: 1,
                }}
              />
              <img
                src={screen01}
                alt="feature"
                style={{
                  width: 200,
                  position: "absolute",
                  left: "10%",
                  zIndex: 1,
                }}
              />
              <img
                src={screen02}
                alt="feature"
                style={{
                  width: 240,
                  position: "absolute",
                  right: "23%",
                  zIndex: 1,
                }}
              />
              <img
                src={screen03}
                alt="feature"
                style={{
                  width: 240,
                  position: "absolute",
                  left: "23%",
                  zIndex: 1,
                }}
              />
              <img
                src={screen01}
                alt="feature"
                style={{
                  width: 280,
                  zIndex: 2,
                }}
              />
            </div>
          }
        />
        <IndexContainer
          heading={"How it Works"}
          title={"Get Moving Together"}
          body={
            "Vaia finds the best trip for you and your crew. All your travel details are in one place, so your crew can get the info you need, when you need it."
          }
          content={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "center",
                width: "100%",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  textAlign: "justify",
                  marginInline: 40,
                  alignItems: "center",
                  justifyContent: "flex-start",
                  maxWidth: 280,
                }}
              >
                <img
                  style={{
                    height: 220,
                    width: 220,
                    borderRadius: 120,
                    margin: 12,
                    objectFit: "cover",
                    boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                  }}
                  src={howitworks01}
                  alt="howitworks"
                />
                <p>
                  Whether you’re gearing up for a friends trip to Portland or
                  planning an offsite meeting with coworkers, Vaia brings your
                  group together to decide on all the important details,
                  so&nbsp;
                  <b>you don’t have to do it alone</b>.
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  textAlign: "justify",
                  marginInline: 40,
                  alignItems: "center",
                  justifyContent: "flex-start",
                  maxWidth: 280,
                }}
              >
                <img
                  style={{
                    height: 220,
                    width: 220,
                    borderRadius: 120,
                    margin: 12,
                    objectFit: "cover",
                    boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                  }}
                  src={howitworks02}
                  alt="howitworks"
                />
                <p>
                  Vote on travel dates, destinations, housing options, and
                  activities, so everyone gets a say. Then set deadlines, send
                  automated reminders, and lock in a plan before you book.&nbsp;
                  <b>
                    Getting everyone to agree on things has never been this easy
                  </b>
                  .
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  textAlign: "justify",
                  marginInline: 40,
                  alignItems: "center",
                  justifyContent: "flex-start",
                  maxWidth: 280,
                }}
              >
                <img
                  style={{
                    height: 220,
                    width: 220,
                    borderRadius: 120,
                    margin: 12,
                    objectFit: "cover",
                    boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                  }}
                  src={howitworks03}
                  alt="howitworks"
                />
                <p>
                  It all begins with an idea — a long weekend in Mexico City,
                  your 30th birthday in Palm Springs, finally seeing your
                  favorite band on tour. Whatever the trip,&nbsp;
                  <b>
                    you should spend more time enjoying yourself and less time
                    sweating the details
                  </b>
                  .
                </p>
              </div>
            </div>
          }
        />
        <IndexContainer
          heading={"Features"}
          title={"Stay up to date with your whole crew."}
          body={
            "Get moving the right way. The entire world is at your fingertips - events, activities, flights, and lodging, all in one place."
          }
          content={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                // background: "red",
                width: "100%",
                flexWrap: "wrap",
              }}
            >
              <FeaturePreview image={feature01} label={"Book Flights"} />
              <FeaturePreview image={feature02} label={"Book Lodging"} />
              <FeaturePreview image={feature03} label={"Stay Notified"} />
              <FeaturePreview image={feature04} label={"Rent Cars"} />
              <FeaturePreview image={feature05} label={"Freeze Prices"} />
            </div>
          }
        />
      </div>
      <svg
        width="100%"
        height="240"
        style={{ marginBottom: 0, display: "block" }}
      >
        <path
          d="M 0,240 C 0,240 0,120 0,120 C 82.30614874815905,128.5694035346097 164.6122974963181,137.13880706921944 246,147 C 327.3877025036819,156.86119293078056 407.85695876288673,168.01417525773195 519,160 C 630.1430412371133,151.98582474226805 771.9598674521354,124.80449189985272 873,120 C 974.0401325478646,115.19550810014728 1034.3035714285713,132.76785714285717 1115,134 C 1195.6964285714287,135.23214285714283 1296.825846833579,120.1240795287187 1379,107 C 1461.174153166421,93.8759204712813 1524.393041237113,82.73582474226804 1623,87 C 1721.606958762887,91.26417525773196 1855.6019882179678,110.93262150220913 1911,119 C 1966.3980117820322,127.06737849779087 1943.199005891016,123.53368924889543 1920,120 C 1920,120 1920,240 1920,240 Z"
          fill="#00b0f0"
          opacity="1"
        />
      </svg>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "#00b0f0",
          paddingTop: 40,
          paddingBottom: 160,
        }}
      >
        <p
          style={{
            fontSize: 36,
            fontWeight: 700,
            margin: 0,
            color: Colors.vWhite,
          }}
        >
          Ready to get moving?
        </p>
        <ButtonUnstyled
          className="pressable"
          onClick={() => setGetMobileAppOpen(true)}
          style={{
            background: Colors.vOrange,
            color: Colors.vWhite,
            border: "0px",
            borderRadius: 4,
            padding: 8,
            cursor: "pointer",
            margin: 16,
            boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
            zIndex: 1,
          }}
        >
          <p
            style={{
              color: Colors.vWhite,
              whiteSpace: "nowrap",
              fontSize: 20,
              fontWeight: 500,
            }}
          >
            Send app to your phone
          </p>
        </ButtonUnstyled>
      </div>
    </div>
  );
}

function FeaturePreview(props: { image: any; label: string }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: 160,
        width: 160,
        background: "#fff",
        borderRadius: 12,
        boxShadow: "0px 0px 12px rgba(0,0,0,0.1)",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        margin: 8,
      }}
    >
      <img src={props.image} height={100} width={100} alt="Feature" />
      <p>{props.label}</p>
    </div>
  );
}
