import { useState, useEffect } from "react";
import Colors from "../styles/Colors";

export default function IndexContainer(props: {
  heading: string;
  title: string;
  body: string;
  content: any;
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  const updateWidthAndHeight = () => {
    setWindowWidth(window.innerWidth);
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "stretch",
        marginBottom: 80,
        marginTop: 80,
        marginInline: windowWidth > 800 ? "8%" : 12,
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "stretch",
          marginInline: windowWidth > 800 ? "4%" : 12,
        }}
      >
        <h3>{props.heading}</h3>
        <h2 style={{ fontWeight: "bold" }}>{props.title}</h2>
        <p style={{ margin: 8 }}>{props.body}</p>
      </div>
      <div
        style={{
          marginTop: 16,
          width: "100%",
          display: "flex",
          flex: 1,
          flexDirection: "row",
        }}
      >
        {props.content}
      </div>
    </div>
  );
}
