import { Drawer, Link, Modal } from "@mui/material";
import { MdClose } from "react-icons/md";
import Colors from "../styles/Colors";
// import logo from "../assets/qr-code.png";
import logo from "../assets/logoBlue.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaRegSmile } from "react-icons/fa";
import { ButtonUnstyled } from "@mui/base";

export default function GetMobileApp(props: {
  visible: boolean;
  handleClose: any;
  width: number;
  height: number;
}) {
  const [phone, setPhone] = useState<string>("");

  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const navigate = useNavigate();

  function validateRoutingNumber() {
    // if (!phone) {
    //   return "Routing number required.";
    // }

    if (!/^[0-9]{10}$/.test(phone)) {
      return false;
    }
    return true;
  }

  var axios = require("axios");
  var data = JSON.stringify({
    fields: {
      phone: phone,
    },
  });

  var config = {
    method: "post",
    url: "https://api.airtable.com/v0/apppN7GPLdU26U726/PhoneNumbers/",
    headers: {
      Authorization: "Bearer keybnv0gNR7elsEEQ",
      "Content-Type": "application/json",
      Cookie: "brw=brwivyZQVPudxASqM",
    },
    data: data,
  };

  function SendText() {
    axios(config)
      .then(function (response: any) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error: any) {
        console.log(error);
      });
  }

  return (
    <>
      <Modal
        open={modalVisible}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            background: "#fff",
            borderRadius: 8,
            padding: 12,
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p
            style={{
              margin: 0,
              fontSize: 16,
              fontWeight: 700,
              color: Colors.vGray,
            }}
          >
            Message Sent!
          </p>
          <p
            style={{
              margin: 0,
              fontSize: 16,
              fontWeight: 400,
              color: Colors.vBlack,
              marginBlock: 12,
            }}
          >
            Nice! We just texted&nbsp;
            <b>
              {phone.charAt(0)}
              {phone.charAt(1)}
              {phone.charAt(2)}-{phone.charAt(3)}
              {phone.charAt(4)}
              {phone.charAt(5)}-{phone.charAt(6)}
              {phone.charAt(7)}
              {phone.charAt(8)}
              {phone.charAt(9)}
              {phone.charAt(10)}
            </b>
            .
            <br />
            <br />
            Check your phone <FaRegSmile />
          </p>
          <ButtonUnstyled
            style={{
              background: Colors.vOrange,
              color: Colors.vWhite,
              border: "0px",
              borderRadius: 4,
              paddingBlock: 8,
              paddingInline: 20,
              cursor: "pointer",
              boxShadow: "1px 1px 2px rgba(0,0,0,0.2)",
              fontSize: 16,
              alignSelf: "flex-end",
            }}
            onClick={(props.handleClose, () => setModalVisible(false))}
          >
            OK
          </ButtonUnstyled>
        </div>
      </Modal>
      <Drawer anchor="top" open={props.visible}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: props.width,
            height: props.height,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              alignSelf: "stretch",
              padding: 20,
            }}
          >
            <img
              src={logo}
              width={100}
              alt="Vaia logo"
              style={{ cursor: "pointer" }}
              onClick={props.handleClose}
            />
            <MdClose
              size={32}
              style={{
                color: Colors.vOrange,
                cursor: "pointer",
              }}
              onClick={props.handleClose}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                maxWidth: 480,
                textAlign: "center",
                margin: 10,
              }}
            >
              <h3 style={{ marginBottom: 24 }}>
                We're still building the world's best travel app. Enter your
                phone number and we'll let you know when you can use Vaia to
                start planning your next group trip.
              </h3>
              <form
                style={{
                  marginBottom: 36,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <label
                  style={{
                    borderRadius: 4,
                    border:
                      !validateRoutingNumber() === true
                        ? "1px solid lightgray"
                        : "1px solid #7AC74F",
                    boxShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                    fontSize: 16,
                    display: "flex",
                    flexDirection: "row",
                    marginInline: 8,
                  }}
                >
                  <div style={{ borderRight: "1px solid lightgray" }}>
                    <p style={{ margin: 8 }}>+1</p>
                  </div>
                  <input
                    type="text"
                    placeholder="(000) 000-0000"
                    style={{
                      border: "0px",
                      background: "transparent",
                      padding: 8,
                    }}
                    maxLength={10}
                    minLength={10}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </label>
                <input
                  type="submit"
                  className={
                    validateRoutingNumber() === true ? "pressable" : ""
                  }
                  value={"Let me know!"}
                  disabled={!validateRoutingNumber()}
                  style={{
                    background:
                      validateRoutingNumber() === true
                        ? Colors.vOrange
                        : Colors.vGray,
                    color: Colors.vWhite,
                    border: "0px",
                    borderRadius: 4,
                    padding: 8,
                    cursor: validateRoutingNumber() === true ? "pointer" : "",
                    boxShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                    fontSize: 16,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    SendText();
                    setModalVisible(true);
                  }}
                />
              </form>
              {/* <h4 style={{ marginBottom: 12, color: Colors.vGray }}>
              Or scan to download.
            </h4>
            <img
              src={logo}
              alt="Vaia logo"
              style={{
                height: 240,
                width: 240,
                // padding: 4,
                border: "1px solid #000",
                marginBottom: 36,
                borderRadius: 12,
              }}
            /> */}
              <p
                style={{
                  color: Colors.vGray,
                  fontSize: 16,
                  maxLines: 1,
                  flexShrink: 0,
                }}
              >
                By clicking "Let me know!", you agree to the&nbsp;
                <Link
                  underline={"none"}
                  style={{ color: Colors.vBlue, cursor: "pointer" }}
                >
                  Terms of Use
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}
