import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import About from "./components/About";
import Landing from "./components/Landing";
// import Company from "./components/Company";
import Support from "./components/Support";
import ScrollToTop from "./helpers/ScrollToTop";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Landing />} />
            {/* <Route path="" element={<Landing />} /> */}
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Support />} />
          </Route>
          <Route path="*" element={<App />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
