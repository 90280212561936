import "../styles/styles.css";
import Colors from "../styles/Colors";
import { Link } from "@mui/material";
import logo from "../assets/logoWhite.png";
import { FaFacebookSquare, FaInstagram } from "react-icons/fa";

export default function Footer() {
  return (
    <div
      style={{
        background: Colors.vBlack,
        padding: 20,
        zIndex: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <LinkContainer
            content={
              <>
                <FooterLinkHeader label={"Company"} />
                {/* <FooterLink label={"About"} /> */}
                <FooterLink label={"About"} link="/" />
                <FooterLink label={"Contact"} link="/contact" />
                {/* <FooterLink label={"Privacy Policy"} />
                <FooterLink label={"Terms & Conditions"} /> */}
              </>
            }
          />
          {/* <LinkContainer
            content={
              <>
                <FooterLinkHeader label={"Partners"} />
                <FooterLink label={"Partner Program"} />
                <FooterLink label={"Contact"} />
              </>
            }
          /> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            flex: 1,
            // background: "red",
          }}
        >
          <img src={logo} height={60} alt="Vaia logo" />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <a
              href="https://www.facebook.com/vaia.us"
              target="_blank"
              rel="noreferrer"
              style={{ color: "white", margin: 12, cursor: "pointer" }}
              className="pressable"
            >
              <FaFacebookSquare size={24} className="pressable" />
            </a>
            <a
              href="https://www.instagram.com/vaia.travel/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "white", margin: 12, cursor: "pointer" }}
              className="pressable"
            >
              <FaInstagram size={24} />
            </a>
          </div>
        </div>
      </div>
      <div
        style={{
          margin: 20,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        {/* <Link style={{ color: Colors.vWhite, margin: 10 }} underline="hover">
          <p
            style={{
              color: Colors.vWhite,
              margin: 0,
              fontSize: 12,
              cursor: "pointer",
            }}
          >
            Terms of Use
          </p>
        </Link> */}
        <p
          style={{
            fontSize: 12,
            color: Colors.vWhite,
            margin: 10,
          }}
        >
          © All rights reserved to JHAI LLC.
        </p>
      </div>
    </div>
  );
}

function LinkContainer(props: { content: any }) {
  return (
    <div style={{ color: Colors.vWhite, textAlign: "left", marginInline: 20 }}>
      {props.content}
    </div>
  );
}

function FooterLinkHeader(props: { label: string }) {
  return (
    <p
      style={{
        color: Colors.vWhite,
        marginBlock: 10,
        marginInline: 0,
        fontSize: 20,
        fontWeight: 800,
      }}
    >
      {props.label}
    </p>
  );
}

function FooterLink(props: { label: string; link?: string }) {
  return (
    <Link
      tabIndex={1}
      href={props.link}
      underline="hover"
      style={{
        display: "flex",
        marginBlock: 10,
        cursor: "pointer",
        fontSize: 15,
        color: Colors.vWhite,
      }}
    >
      {props.label}
    </Link>
  );
}
