import { ButtonUnstyled } from "@mui/base";
import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import Colors from "../styles/Colors";
import "../styles/styles.css";
import IndexContainer from "./IndexContainer";
import { useNavigate } from "react-router-dom";

export default function Support() {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  const updateWidthAndHeight = () => {
    setWindowWidth(window.innerWidth);
  };

  const navigate = useNavigate();

  function validateName(): string {
    if (name.length === 0) {
      return "Name is required";
    }

    return "";
  }

  function validateEmail(): string {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return "Invalid email address";
    }

    return "";
  }

  function validateMessage(): string {
    if (message.length === 0) {
      return "Message is required";
    }

    return "";
  }

  function validate() {
    return !(!!validateName() || !!validateEmail() || !!validateMessage());
  }

  var axios = require("axios");
  var data = JSON.stringify({
    fields: {
      Name: name,
      Email: email,
      Message: message,
      Status: "Todo",
    },
    typecast: true,
  });

  var config = {
    method: "post",
    url: "https://api.airtable.com/v0/apppN7GPLdU26U726/Support",
    headers: {
      Authorization: "Bearer keybnv0gNR7elsEEQ",
      "Content-Type": "application/json",
      Cookie: "brw=brwivyZQVPudxASqM",
    },
    data: data,
  };

  function Send() {
    axios(config)
      .then(function (response: any) {
        console.log(JSON.stringify(response.data));
        setModalVisible(true);
      })
      .catch(function (error: any) {
        console.log(error);
      });
  }

  return (
    <div
      style={{
        background: "linear-gradient(#fff,#eaeaea)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 20,
        zIndex: 1,
      }}
    >
      <Modal
        open={modalVisible}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            background: "#fff",
            borderRadius: 8,
            padding: 12,
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p
            style={{
              margin: 0,
              fontSize: 16,
              fontWeight: 700,
              color: Colors.vGray,
            }}
          >
            Success!
          </p>
          <p
            style={{
              margin: 0,
              fontSize: 16,
              fontWeight: 400,
              color: Colors.vBlack,
              marginBlock: 12,
            }}
          >
            We've received your message! Someone will reach out shortly.
          </p>
          <ButtonUnstyled
            style={{
              background: Colors.vOrange,
              color: Colors.vWhite,
              border: "0px",
              borderRadius: 4,
              paddingBlock: 8,
              paddingInline: 20,
              cursor: "pointer",
              boxShadow: "1px 1px 2px rgba(0,0,0,0.2)",
              fontSize: 16,
              alignSelf: "flex-end",
            }}
            onClick={() => navigate("/")}
          >
            OK
          </ButtonUnstyled>
        </div>
      </Modal>
      <IndexContainer
        heading={""}
        title={"Contact"}
        body={
          "Question? Concern? Just want to chat? Send us a message and we'll get back to you shortly."
        }
        content={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              marginBottom: 240,
            }}
          >
            <form
              style={{ flex: 1, marginInline: windowWidth > 800 ? "4%" : 12 }}
            >
              <label
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 20,
                }}
              >
                Name
                <input
                  type="text"
                  placeholder="Jane Doe"
                  style={
                    name.length === 0
                      ? {
                          padding: 8,
                          marginTop: 4,
                          borderRadius: 4,
                          border: "1px solid lightgray",
                          boxShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                          fontSize: 16,
                        }
                      : {
                          padding: 8,
                          marginTop: 4,
                          borderRadius: 4,
                          border: "1px solid #7AC74F",
                          boxShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                          fontSize: 16,
                        }
                  }
                  onChange={(e) => setName(e.target.value)}
                />
              </label>
              <label
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 20,
                }}
              >
                Email
                <input
                  type="text"
                  placeholder="hello@vaia.us"
                  style={
                    !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) === true
                      ? {
                          padding: 8,
                          marginTop: 4,
                          borderRadius: 4,
                          border: "1px solid lightgray",
                          boxShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                          fontSize: 16,
                        }
                      : {
                          padding: 8,
                          marginTop: 4,
                          borderRadius: 4,
                          border: "1px solid #7AC74F",
                          boxShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                          fontSize: 16,
                        }
                  }
                  onChange={(evt) => setEmail(evt.target.value)}
                />
              </label>

              <label
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 20,
                }}
              >
                What's up?
                <textarea
                  placeholder="Tell us what's on your mind..."
                  rows={10}
                  style={
                    message.length === 0
                      ? {
                          padding: 8,
                          marginTop: 4,
                          borderRadius: 4,
                          border: "1px solid lightgray",
                          boxShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                          fontSize: 16,
                        }
                      : {
                          padding: 8,
                          marginTop: 4,
                          borderRadius: 4,
                          border: "1px solid #7AC74F",
                          boxShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                          fontSize: 16,
                        }
                  }
                  onChange={(e) => setMessage(e.target.value)}
                />
              </label>
              <input
                type="submit"
                className={validate() ? "pressable" : ""}
                value={"Submit"}
                disabled={!validate()}
                onClick={(e) => {
                  e.preventDefault();
                  Send();
                }}
                style={
                  validate()
                    ? {
                        background: Colors.vOrange,
                        color: Colors.vWhite,
                        border: "0px",
                        borderRadius: 4,
                        padding: 8,
                        cursor: "pointer",
                        boxShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                        fontSize: 16,
                        alignSelf: "stretch",
                        width: "100%",
                      }
                    : {
                        background: Colors.vGray,
                        color: Colors.vWhite,
                        border: "0px",
                        borderRadius: 4,
                        padding: 8,
                        boxShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                        fontSize: 16,
                        alignSelf: "stretch",
                        width: "100%",
                      }
                }
              />
            </form>
          </div>
        }
      />
    </div>
  );
}
