import "../styles/styles.css";
import { useEffect, useState } from "react";
import { Link, Drawer } from "@mui/material";
import { ButtonUnstyled } from "@mui/base";
import { MdClose, MdMenu } from "react-icons/md";
import logo from "../assets/logoBlue.png";
import Colors from "../styles/Colors";
import GetMobileApp from "./GetMobileApp";

export default function Header() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [menuOpen, setMenuOpen] = useState(false);
  const [getMobileAppOpen, setGetMobileAppOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(window.scrollY);

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  useEffect(() => {
    window.addEventListener("scroll", updateScrollPosition);
    return () => window.removeEventListener("scroll", updateScrollPosition);
  });

  const updateWidthAndHeight = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  const updateScrollPosition = () => {
    setScrollPosition(window.scrollY);
  };

  const mobileTrigger = 600;
  const scrollTrigger = 200;

  return (
    <div
      style={{
        position: "sticky",
        top: 0,
        left: 0,
        right: 0,
        margin: 0,
        background: "#fff",
        paddingInline: 20,
        paddingBlock: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 20,
      }}
    >
      {windowWidth < mobileTrigger ? (
        <>
          <MdMenu
            tabIndex={1}
            size={40}
            style={{ color: Colors.vGray }}
            cursor="pointer"
            onClick={() => setMenuOpen(true)}
          />
          <Drawer anchor="left" open={menuOpen}>
            <div
              style={{
                width: 300,
                maxWidth: windowWidth - 10,
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  alignSelf: "stretch",
                  padding: 8,
                }}
              >
                <MdClose
                  size={36}
                  style={{
                    color: Colors.vGray,
                    cursor: "pointer",
                  }}
                  onClick={() => setMenuOpen(false)}
                />
              </div>
              <HeaderContent />

              <ButtonUnstyled
                className="pressable"
                onClick={() => setGetMobileAppOpen(true)}
                style={{
                  background: Colors.vOrange,
                  color: Colors.vWhite,
                  border: "1px solid #F04000",
                  borderRadius: 4,
                  padding: 4,
                  cursor: "pointer",
                  margin: 4,
                  boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                }}
              >
                <p style={{ color: Colors.vWhite, whiteSpace: "nowrap" }}>
                  Send App to your phone
                </p>
              </ButtonUnstyled>
            </div>
          </Drawer>
        </>
      ) : null}

      <Link underline={"none"} href="/">
        <img src={logo} width={100} alt="Vaia logo" />
      </Link>
      {windowWidth > mobileTrigger ? (
        scrollPosition < scrollTrigger ? null : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <HeaderContent />
            <ButtonUnstyled
              className="pressable"
              onClick={() => setGetMobileAppOpen(true)}
              style={{
                background: Colors.vOrange,
                color: Colors.vWhite,
                border: "1px solid #F04000",
                borderRadius: 4,
                padding: 4,
                cursor: "pointer",
                margin: 4,
                boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
              }}
            >
              <p style={{ color: Colors.vWhite, whiteSpace: "nowrap" }}>
                Send App to your phone
              </p>
            </ButtonUnstyled>
          </div>
        )
      ) : null}
      <GetMobileApp
        visible={getMobileAppOpen}
        handleClose={() => setGetMobileAppOpen(false)}
        width={windowWidth}
        height={windowHeight}
      />
    </div>
  );
}

function HeaderContent() {
  return (
    <>
      <Link
        underline="hover"
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          color: Colors.vBlue,
          margin: 12,
        }}
        href="../"
      >
        <p
          style={{
            color: Colors.vBlue,
            whiteSpace: "nowrap",
            margin: 0,
          }}
        >
          About
        </p>
      </Link>
      {/* <Link
        underline="hover"
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          color: Colors.vBlue,
          margin: 12,
        }}
        href="../company"
      >
        <p
          style={{
            color: Colors.vBlue,
            whiteSpace: "nowrap",
            margin: 0,
          }}
        >
          Company
        </p>
      </Link> */}
      <Link
        underline="hover"
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          color: Colors.vBlue,
          margin: 12,
        }}
        href="/contact"
      >
        <p
          style={{
            color: Colors.vBlue,
            whiteSpace: "nowrap",
            margin: 0,
          }}
        >
          Contact
        </p>
      </Link>
    </>
  );
}
