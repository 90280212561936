import { ButtonUnstyled } from "@mui/base";
import { useState } from "react";
import "../styles/styles.css";
import Colors from "../styles/Colors";
// import axios from "axios";

export default function About() {
  var axios = require("axios");

  const [data, setData] = useState("");

  var config = {
    method: "get",
    url: "https://tequila-api.kiwi.com/locations/query?term=New York&locale=en-US&location_types=airport&active_only=true&limit=100",
    headers: {
      apikey: "tnBqyd-NJzif4YEf_VWRKcF1Mz9P7FXR",
      // "Content-Encoding": "gzip",
    },
  };

  return (
    <div
      style={{
        background: "linear-gradient(#fff,#eaeaea)",
        minHeight: "100vh",
      }}
    >
      <ButtonUnstyled
        className="pressable"
        onClick={() =>
          axios(config)
            .then(function (response: any) {
              setData(JSON.stringify(response.data.locations));
            })
            .catch(function (error: any) {
              alert(JSON.stringify(error));
            })
        }
        style={{
          background: Colors.vOrange,
          color: Colors.vWhite,
          border: "1px solid #F04000",
          borderRadius: 4,
          padding: 4,
          cursor: "pointer",
          margin: 16,
          boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
          zIndex: 1,
        }}
      >
        <p style={{ color: Colors.vWhite, whiteSpace: "nowrap" }}>
          Test the API - Tequila Location Search (cities)
        </p>
      </ButtonUnstyled>
      <p>{data}</p>
    </div>
  );
}
